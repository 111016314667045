/* src/pages/BankingGuidePage.css */
.blog-page {
  max-width: 1000px;
  margin: auto;
  padding: 2rem;
  background-color: #f0f0f0;
  border-radius: 0.5rem;
}

.blog-page h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.blog-page h2 {
  font-size: 2rem;
  margin-top: 1.5rem;
}

.blog-page p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.blog-page ul {
  list-style-type: disc;
  margin-left: 2rem;
}

.blog-page li {
  margin-bottom: 0.5rem;
}

.important {
  color: red;
}
