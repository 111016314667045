/* src/components/Navbar.css */
.navbar {
  background-color: #333;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  margin-right: 2rem;
}

.navbar-brand a {
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold; /* Make navbar brand bold */
}

.navbar-nav {
  list-style: none;
  display: flex;
  gap: 1.5rem;
  margin: 0;
  padding: 0;
}

.nav-item .nav-link {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease; /* Smooth transition for color change */
}

.nav-item .nav-link:hover {
  color: #ccc; /* Lighter color on hover */
  text-decoration: none;
  font-weight: bold; /* Make navbar items bold on hover */
}
