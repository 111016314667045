/* src/pages/LandingPage.css */
.landing-page {
  max-width: 900px; /* Example: Set a maximum width */
  margin: auto; /* Center align and add margin to sides */
  padding: 2rem; /* Adjust padding for content spacing */
  background-color: #f0f0f0;
  text-align: center;
  border-radius: 0.5rem;
}

.landing-page h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.landing-page p {
  font-size: 1.3rem;
  line-height: 1.6;
}

.landing-page a {
  display: block;
  margin-top: 1rem;
  color: #007bff;
  text-decoration: none;
  font-size: 1.2rem;
}

.landing-page a:hover {
  text-decoration: underline;
}

.landing-page-image {
  max-width: 100%;
  height: auto;
  border-radius: 0.5rem;
  margin-top: 1rem;
}
