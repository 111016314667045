.sitemap-page {
  margin: 20px auto;
  padding: 20px;
  max-width: 800px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sitemap-page h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.sitemap-page ul {
  list-style-type: none;
  padding: 0;
}

.sitemap-page li {
  margin-bottom: 10px;
}

.sitemap-page a {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
}

.sitemap-page a:hover {
  text-decoration: underline;
}
