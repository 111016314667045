/* src/pages/GuidePage.css */
.guide-page {
  max-width: 800px;
  margin: auto;
  padding: 2rem;
  background-color: #f0f0f0;
  border-radius: 0.5rem;
}

.guide-page h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.guide-page p {
  font-size: 1.2rem;
  line-height: 1.6;
}

.guide-page ul {
  list-style-type: none;
  padding: 0;
}

.guide-page li {
  margin-bottom: 0.5rem;
}

.guide-page a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.guide-page a:hover {
  text-decoration: underline;
}
