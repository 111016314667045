/* src/components/AboutPage.css */
.about-page {
  max-width: 900px; /* Example: Set a maximum width */
  margin: auto; /* Center align and add margin to sides */
  padding: 2rem; /* Adjust padding for content spacing */
  background-color: #f0f0f0;
  text-align: center;
  border-radius: 0.5rem;
}

.about-page h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.about-page p {
  font-size: 1.3rem;
  line-height: 1.6;
}
